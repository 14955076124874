<template>
  <div :class="`m2 wrapper wrapper--${this.border}`">
    <Loading v-if="loading" />
    <div v-else>
      <div :class="`_text-center bg--${this.item_data.bu}`">
        Itemnummer: {{ this.item_data.nummer }} - {{ this.item_data.bu }}
      </div>
      <div class="flexbox spaceevenly">
        <img class="m1 border maxheight--picture"
          :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item_data.nummer}/original`" />
        <div class="m1">
          <TextBox label="Soort" :value="`${this.item_data.soortnaam}`" />
          <TextBox label="Opbouw" :value="`${this.item_data.opbouwnaam}`" />
          <TextBox label="Merk" :value="`${this.item_data.merknaam}`" />
          <TextBox label="Type" :value="`${this.item_data.typenaam}`" />
          <TextBox label="Chassis Nr." :value="`${this.item_data.chassisnummer}`" />
        </div>
        <div class="m1">
          <TextBox v-if="checkroles(
            ['marges_zien'],
            msalInstance.getAllAccounts()[0].idTokenClaims.roles
          )
            " label="Vraagprijs" :value="`€ ${this.item_data.vraagprijs}`" />
          <TextBox label="KM stand" :value="`${this.item_data.kmstand}`" />
          <TextBox label="Reg. jaar" :value="`${this.item_data.regjaar}`" />
          <TextBox label="Kenteken" :value="`${this.item_data.kenteken}`" />
          <TextBox label="Afm. (LxBxH)"
            :value="`${this.item_data.totlen} x ${this.item_data.totbr} x ${this.item_data.toth}`" />
        </div>
        <div class="m1">
          <TextBox label="Status" :value="`${this.item_data.status}`" />
          <TextBox label="Consignatie" :value="`${this.item_data.consignatie}`" />
          <TextBox label="Marge" :value="`${this.item_data.marge}`" />
          <TextBox v-if="this.item_data.Locatie" label="Locatie" :value="`${this.item_data.Locatie}`" />
          <TextBox v-if="this.item_data.gereserveerd" label="Gereserveerd" :value="`${this.item_data.gereserveerd}`" />
          <TextBox v-if="this.item_data.RdwStatus" label="RDW status" :value="`${this.item_data.RdwStatus}`" />
          <TextBox v-if="this.item_data.LaatsteAanpassing" label="RDW laatste aanpassing"
            :value="`${this.item_data.LaatsteAanpassing}`" />
        </div>
        <div class="small_timeline--div">
          <SmallTimeLine :timeline_data="time_in_between" />
        </div>
        <span class="w-100 text-center">Afschrijving: €{{ item_data.afschrijving.slice(0, -3) }} ({{
          item_data.afschrperc
        }}
          %)</span>
      </div>
      <div>
        <Tabs>
          <Tab title="<span><i class='fas fa-history'></i> Tijdlijn</span>" v-if="checkroles(
                [
                  'admin',
                  'it',
                  'comad',
                  'verkoper_trucks',
                  'verkoper_vans',
                  'secretariaat',
                  'delivery',
                  'marketing',
                  'inkoper',
                  'management',
                  'transport',
                ],
                msalInstance.getAllAccounts()[0].idTokenClaims.roles
              )
              ">
            <Timeline :id="id" :klant_data="item_data" />
          </Tab>
          <Tab title="<span><i class='fas fa-comments-dollar'></i> Transacties</span>" :selected="true" v-if="checkroles(
            [
              'binnenkomst',
              'finad',
              'kcc',
              'telefonistes',
              'admin',
              'it',
              'hr',
              'comad',
              'verkoper_trucks',
              'verkoper_vans',
              'secretariaat',
              'delivery',
              'terrein',
              'werkplaats',
              'marketing',
              'inkoper',
              'management',
              'magazijn',
              'transport',
            ],
            msalInstance.getAllAccounts()[0].idTokenClaims.roles
          )
            ">
            <div class="flexbox spaceevenly">
              <div class="m1">
                <h3 class="_text-center">Inkoop</h3>
                <TextBox label="Inkoper" :value="`${this.item_data.inkopernaam}`" />
                <router-link v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " :to="{
    name: 'leverancier',
    params: { id: this.item_data.leverancier },
  }">
                  <TextBox label="Leverancier" :value="`${this.item_data.leverancier}`" />
                </router-link>
                <TextBox v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Leverancier naam" :value="`${this.item_data.levnaam}`" />
                <TextBox v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Leverancier adres" :value="`${this.item_data.levadres}`" />
                <TextBox v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Leverancier stad" :value="`${this.item_data.levstad}`" />
                <TextBox v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Leverancier land" :value="`${this.item_data.levland}`" />
                <TextBox label="Inkoop" :value="`${this.item_data.orderdatum}`" />
                <TextBox label="Ontvangst" :value="`${this.item_data.ontvangstdatum}`" />
                <TextBox v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Inkooporder" :value="`${this.item_data.inkooporder}`" />
                <TextBox v-if="checkroles(
                  ['marges_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Commissie" :value="`€ ${this.item_data.inkoopprovisie}`" />
                <TextBox v-if="checkroles(
                  ['marges_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Inkoop prijs" :value="`€ ${this.item_data.inkoopbedrag}`" />
                  <TextBox v-if="checkroles(
                  ['marges_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Afschrijving" :value="`€ ${this.item_data.afschrijving.slice(0, -3)}`" />
              </div>
              <div class="m1">
                <h3 class="_text-center">Shipped to</h3>
                <router-link v-if="checkroles(
                  ['deb_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " :to="{
    name: 'klantpagina',
    params: { id: this.item_data.aflevernummer },
  }">
                  <TextBox label="Aflever nummer" :value="`${this.item_data.aflevernummer}`" />
                </router-link>
                <TextBox v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Aflever naam" :value="`${this.item_data.aflevernaam}`" />
                <TextBox v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Aflever adres" :value="`${this.item_data.afleveradres}`" />
                <TextBox v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Aflever stad" :value="`${this.item_data.afleverstad}`" />
                <TextBox v-if="checkroles(
                  ['lev_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Aflever land" :value="`${this.item_data.afleverland}`" />
              </div>
              <div class="m1">
                <h3 class="_text-center">Verkoop</h3>
                <TextBox label="Verkoper" :value="`${this.item_data.verkopernaam}`" />
                <router-link v-if="checkroles(
                  ['deb_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " :to="{
    name: 'klantpagina',
    params: { id: this.item_data.klant },
  }">
                  <TextBox label="Klant" :value="`${this.item_data.klant}`" />
                </router-link>
                <TextBox v-if="checkroles(
                  ['deb_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Klant naam" :value="`${this.item_data.klantnaam}`" />
                <TextBox v-if="checkroles(
                  ['deb_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Klant adres" :value="`${this.item_data.klantadres}`" />
                <TextBox v-if="checkroles(
                  ['deb_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Klant stad" :value="`${this.item_data.klantstad}`" />
                <TextBox v-if="checkroles(
                  ['deb_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Klant land" :value="`${this.item_data.klantland}`" />
                <TextBox label="Verkoop" :value="`${this.item_data.factuurdatum}`" />
                <TextBox label="Aflevering" :value="`${this.item_data.afleverdatum}`" />
                <router-link v-if="checkroles(
                  ['deb_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " :to="{
    name: 'verkooporderdetails',
    params: { id: this.item_data.orderno },
  }">
                  <TextBox label="Verkoop order" :value="`${this.item_data.orderno}`" />
                </router-link>

                <TextBox label="Garantie" :value="`${this.item_data.garantie}`" />
              </div>
              <div class="m1">
                <h3 class="_text-center">Verkoop prijzen</h3>
                <TextBox v-if="checkroles(
                  ['marges_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Factuurprijs" :value="`€ ${this.item_data.verkoopbedrag}`" />
                <TextBox label="Documenten" :value="`€ ${this.item_data.documenten}`" />
                <TextBox label="Transport" :value="`€ ${this.item_data.transport}`" />
                <TextBox label="Commissie" :value="`€ ${this.item_data.commissie}`" />
                <TextBox label="Werkplaats" :value="`€ ${this.item_data.werkplaats}`" />
                <TextBox v-if="!checkroles(
                  ['geen_marges'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
                  " label="Netto prijs" :value="`€ ${this.item_data.nettoprijs}`" />
              </div>
              <div class="w100">
                <div class="_text-center target__country bold m2">
                  Gekocht voor:
                </div>
                <hr class="m0" />
                <div class="m2 _text-center bold">{{ target_landen }}</div>
              </div>
            </div>
          </Tab>
          <Tab title="<span><i class='fas fa-map-marker-alt'></i> Locatie</span>">
            <div class="flex _row">
              <GmapMap :center="{
                lat: Number(item_data.Latitude),
                lng: Number(item_data.Longitude),
              }" :zoom="18" map-type-id="satellite" style="width: 100%; height: 500px">
                <gmap-info-window :options="infoOptions" :position="infoPosition" :opened="infoOpened"
                  @closeclick="infoOpened = false">
                  {{ infoContent }}
                </gmap-info-window>
                <GmapMarker :key="index" v-for="(m, index) in markers" :position="m" @click="toggleInfo(m, index)" />
              </GmapMap>
            </div>
          </Tab>
          <Tab :onOpen="getFinancialData" title="<span><i class='fas fa-coins'></i> Financieel</span>" v-if="checkroles(
            [
              'finad',
              'admin',
              'it',
              'comad',
              'verkoper_trucks',
              'verkoper_vans',
              'secretariaat',
              'delivery',
              'inkoper',
              'management',
            ],
            msalInstance.getAllAccounts()[0].idTokenClaims.roles
          )
            ">
            <Loading v-if="financial_loading" />
            <div v-else class="d-flex flex-row">
              <div class="flex-grow-1">
                <ItemFinancialTable v-if="item_financial_data" :table_data="item_financials"
                  :financial_data="item_financial_data" :table_style="`${this.item_data.bu}`" />
              </div>
            </div>
          </Tab>
          <Tab title="<span><i class='fas fa-tools'></i> Werkplaats</span>" :onOpen="getWorkOrderData">
            <Loading v-if="workorder_loading" />
            <ItemWorkOrdersTable v-else :table_data="item_workorders" :table_style="`${this.item_data.bu}`" />
          </Tab>
          <Tab title="<span
                ><i class='fas fa-shipping-fast'></i> Transport / Export
                doc.</span
              >" :onOpen="getTransportData">
            <Loading v-if="transport_loading" />
            <ItemTransportTable v-else :table_data="item_transport" :table_style="this.item_data.bu"
              :export_documents="export_documents" />
          </Tab>
          <Tab title="<span
                ><i class='fas fa-sticky-note'></i> Aantekeningen</span
              >" :onOpen="getNotesData">
            <Loading v-if="note_loading" />
            <div v-else>
              <b-button v-b-modal.create class="m-2"><i class="fas fa-sticky-note" /> + Nieuwe notitie
                toevoegen</b-button>
              <table :class="`w-100 table--default table__border--${this.item_data.bu}`">
                <thead :class="`table__head--${this.item_data.bu}`">
                  <tr>
                    <th class="table__head--text table__head--md">Gebruiker</th>
                    <th class="table__head--text table__head--md">Datum</th>
                    <th class="table__head--text">Aantekening</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in item_notes" :key="key" class="table__row--color">
                    <td class="table__cell--default">
                      {{ item.Gebruiker }}
                    </td>
                    <td class="table__cell--default">{{ item.datum }}</td>
                    <td class="table__cell--default">
                      <div class="notes__textbox">
                        {{ item.Aantekeningen }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab>
          <Tab title="<span><i class='fas fa-angry'></i> CRM Klachtenbestand</span>" :onOpen="getCRMComplaintsInfo">
            <Loading v-if="crm_loading" />
            <div v-else>
              <table :class="`w-100 table--default table__border--${this.item_data.bu}`">
                <thead :class="`table__head--${this.item_data.bu}`">
                  <tr>
                    <th class="table__head--text table__head--md">Titel</th>
                    <th class="table__head--text table__head--md">
                      ticketnummer
                    </th>
                    <th class="table__head--text">Omschrijving</th>
                    <th class="table__head--text">Notities</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in crm_complaints" :key="key" class="table__row--color">
                    <td class="table__cell--default">
                      {{ item.title }}
                    </td>
                    <td class="table__cell--default">
                      {{ item.ticketnumber }}
                    </td>
                    <td class="table__cell--default">
                      <div class="notes__textbox">
                        {{ item.description }}
                      </div>
                    </td>
                    <td class="table__cell--default w50">
                      <div v-for="(note, key) in item.notes" :key="key">
                        <span v-b-toggle="`${note.annotationid}`" class="m-1"><i class="fas fa-caret-right" />
                          {{ note.createdby_fullname }} - {{ note.createdon }} -
                          {{ note.subject }}</span>
                        <b-collapse :id="note.annotationid" class="position-relative w100">
                          <b-card class="mr-3">
                            <div class="border-secondary bg-white" v-html="note.notetext"></div>
                          </b-card>
                        </b-collapse>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab>
          <Tab title="<span><i class='fas fa-wrench'></i> Externe Werkopdrachten</span>"
            v-if="this.external_wo_info && this.external_wo_info[0]">
            <h3>Externe werkopdrachten:</h3>
            <ExternalWorkingOrderTable :table_header="workorder_table_header" :table_data="this.external_wo_info"
              :table_style="`${this.item_data.bu}`" :first_column="this.first_column"
              :second_column="this.second_column" />
          </Tab>
        </Tabs>
      </div>
      <div>
        <b-modal id="create" ref="note_modal">
          <template #modal-header>
            <h2>Voeg notitie toe</h2>
          </template>
          <textarea class="form-control" id="note_field" rows="3" v-model.lazy="note_model"></textarea>
          <template #modal-footer>
            <div class="w-100">
              <b-button variant="primary" size="sm" class="float-right" @click="postNote">
                Toevoegen
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/functions/request.js";
import Timeline from "@/components/itempage/Timeline";
import ExternalWorkingOrderTable from "@/components/itempage/ExternalWorkingOrderTable";
import TextBox from "@/components/TextBox";
import Loading from "@/components/Loading";
import ItemFinancialTable from "@/components/itempage/ItemFinancialTable.vue";
import ItemWorkOrdersTable from "@/components/itempage/ItemWorkOrdersTable.vue";
import ItemTransportTable from "@/components/itempage/ItemTransportTable.vue";
import hasIndex from "@/functions/hasIndex";
import { msalInstance } from "vue-msal-browser";
import Tab from "@/components/Tabs/Tab.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import SmallTimeLine from "@/components/itempage/SmallTimeLine.vue";

export default {
  components: {
    Timeline,
    ExternalWorkingOrderTable,
    TextBox,
    ItemFinancialTable,
    ItemWorkOrdersTable,
    Loading,
    ItemTransportTable,
    Tabs,
    Tab,
    SmallTimeLine,
  },
  props: ["id"],
  data: () => ({
    markers: [],
    item_financials: null,
    item_data: null,
    item_financial_data: null,
    item_workorders: null,
    external_wo_info: null,
    gereserveerd: null,
    item_transport: null,
    note_model: null,
    first_column: null,
    second_column: null,
    loading: true,
    workorder_table_header: [
      "Opdracht",
      "Status",
      "Soort",
      "Leverancier",
      "Datum",
      "Prijs",
    ],
    infoPosition: null,
    infoContent: null,
    infoOpened: false,
    infoCurrentKey: null,
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -35,
      },
    },
    item_notes: null,
    border: 200,
    financial_loading: true,
    transport_loading: true,
    workorder_loading: true,
    crm_loading: true,
    note_loading: true,
    target_landen: null,
    crm_complaints: null,
    time_in_between: null,
    export_documents: null,
  }),
  mounted() {
    this.getDataFromId(this.id);
  },
  watch: {
    id(newid) {
      this.getDataFromId(newid);
      this.loading = true;
      this.item_financial_data = null;
      this.external_wo_info = null;
      this.financial_loading = true;
      this.transport_loading = true;
      this.workorder_loading = true;
      this.note_loading = true;
    },
  },
  methods: {
    getDataFromId() {
      request(`item/${this.id}`, "GET").then(({ item_info, error }) => {
        if (error) this.error = error;
        this.item_data = item_info[0];
        this.border = this.item_data.bu;

        this.markers = [
          {
            lat: Number(this.item_data.Latitude),
            lng: Number(this.item_data.Longitude),
            full_name: this.item_data.nummer,
            type: this.item_data.merknaam,
          },
        ];
      });

      request(`external-workorders/${this.id}`, "GET").then(
        ({ external_wo_info }) => {
          this.external_wo_info = external_wo_info.external_workingorder;
          this.first_column = external_wo_info.array_first_column;
          this.second_column = external_wo_info.array_second_column;
        }
      );

      request(`item/target-landen/${this.id}`, "GET").then(
        ({ target_landen }) => {
          this.target_landen = target_landen.toString();
        }
      );

      request(`item/time-in-between-bar/${this.id}`, "GET").then(
        ({ time_in_between }) => {
          this.time_in_between = time_in_between;
          this.loading = false;
        }
      );
    },

    getFinancialData() {
      if (this.financial_loading) {
        request(`item/financials/${this.id}`, "GET").then(
          ({ item_financials, item_financial_details }) => {
            this.item_financials = item_financials;
            this.item_financial_data = item_financial_details;
            this.financial_loading = false;
          }
        );
      }
    },

    getTransportData() {
      if (this.transport_loading) {
        request(`item/transport/${this.id}`, "GET").then(
          ({ item_transport }) => {
            this.item_transport = item_transport.Transportdoc;
          }
        );

        request(`export-documents-by-itemno/${this.id}`).then(
          ({ export_documents }) => {
            this.export_documents = export_documents;
            this.transport_loading = false;
          }
        );
      }
    },

    getWorkOrderData() {
      if (this.workorder_loading) {
        request(`item/workorders/${this.id}`, "GET").then(
          ({ item_workorders }) => {
            this.item_workorders = item_workorders;
            this.workorder_loading = false;
          }
        );
      }
    },

    getNotesData() {
      if (this.note_loading) {
        request(`get-notes/${this.id}`, "GET").then(({ item_notes }) => {
          this.item_notes = item_notes;
          this.note_loading = false;
        });
      }
    },

    getPosition(marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
    },

    toggleInfo(marker, key) {
      this.infoPosition = this.getPosition(marker);
      this.infoContent = `${marker.full_name} - ${marker.type}`;
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
        return;
      }
      this.infoOpened = true;
      this.infoCurrentKey = key;
    },

    postNote() {
      const data = {
        itemno: this.id,
        gebruiker: msalInstance.getAllAccounts()[0].idTokenClaims.name,
        datum: this.moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        aantekeningen: this.note_model,
      };

      request("post-note", "POST", data).then((values) => {
        let toastProps = {};
        if (hasIndex(values.message, "toegevoegd")) {
          toastProps = {
            message: values.message,
            type: "success",
          };
          this.$refs["note_modal"].hide();
          this.item_notes.unshift({
            Gebruiker: msalInstance.getAllAccounts()[0].idTokenClaims.name,
            datum: this.moment().format("YYYY-MM-DD"),
            Aantekeningen: this.note_model,
          });
        } else {
          toastProps = {
            message: values.error,
            type: "danger",
          };
          this.$refs["note_modal"].hide();
        }

        this.$store.dispatch("addNotification", toastProps);
      });
    },

    getCRMComplaintsInfo() {
      request(`item/crm-complaints/${this.id}`, "GET").then(
        ({ crm_complaints }) => {
          console.log(crm_complaints);
          this.crm_complaints = crm_complaints;
          this.crm_loading = false;
        }
      );
    },
  },
};
</script>
