<template>
  <div class="d-flex flex-row">
    <div class="flex-grow-2 p-2">
      <h5 class="text-center">Transport</h5>
      <table :class="`m-2 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">#</th>
            <th class="table__head--text">#</th>
            <th class="table__head--text">Datum</th>
            <th class="table__head--text">Locatie</th>
            <th class="table__data--right table__head--text">Kosten</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in table_data" :key="key" v-on:click="openDetails(key)" :class="`table__row--color`">
            <td class="table__cell--default">
              <div v-if="item.soortnaam == 'halen'">
                <span v-b-tooltip.hover title="Halen" class="green"><i class="fas fa-arrow-left"></i></span>
              </div>
              <div v-if="item.soortnaam == 'wordt gebracht'">
                <span v-b-tooltip.hover title="Wordt gebracht" class="yellow"><i class="fas fa-arrow-left"></i></span>
              </div>
              <div v-if="item.soortnaam == 'wegbrengen'">
                <span v-b-tooltip.hover title="wegbrengen" class="blue"><i class="fas fa-arrow-right"></i></span>
              </div>
            </td>
            <td class="table__cell--default">
              <div v-if="item.statusnaam == 'historie'">
                <span class="dot dot--orange" v-b-tooltip.hover title="Historie"></span>
              </div>
              <div v-if="item.statusnaam == 'ingepland'">
                <span class="dot dot--blue" v-b-tooltip.hover title="Ingepland"></span>
              </div>
              <div v-if="item.statusnaam == 'nieuw'">
                <span class="dot dot--red" v-b-tooltip.hover title="Nieuw"></span>
              </div>
            </td>
            <td class="table__cell--default">{{ item.datum }}</td>
            <td class="table__cell--default">{{ item.locatie }}</td>
            <td class="table__data--right table__cell--default">€ {{ item.kosten }}</td>
          </tr>
        </tbody>
      </table>
      <h5 class="text-center">Export Documenten</h5>
      <table :class="`m-2 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">Status melding</th>
            <th class="table__head--text">Doc code</th>
            <th class="table__head--text">Borg</th>
            <th class="table__head--text">Kwitantie</th>
            <th class="table__data--right table__head--text">Betaaldatum</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in export_documents" :key="key" v-on:click="openDetails(key)"
            :class="`table__row--color`">
            <td class="table__cell--default">{{ item.ecsstatus }}</td>
            <td class="table__cell--default">{{ item.doccode }}</td>
            <td class="table__data--right table__cell--default">€ {{ item.borg }}</td>
            <td class="table__data--right table__cell--default">{{ item.kwitantie }}</td>
            <td class="table__data--right table__cell--default">{{ item.betaalddatum }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex-grow-1" v-if="clicked">
      <div :class="`transport transport-header transport-header--${table_data[0].bu}`
        ">
        <TextBox :label="`Ordernummer`" :value="table_data[arrkey].header[0].ordernummer" />
        <TextBox :label="`Datum`" :value="table_data[arrkey].header[0].datum" />
        <TextBox :label="`Opdrachtgever`" :value="table_data[arrkey].header[0].naam" />
      </div>

      <table :class="`w-100 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">#</th>
            <th class="table__head--text">#</th>
            <th class="table__head--text">item</th>
            <th class="table__head--text">Tramsporteur</th>
            <th class="table__head--text">vervoer</th>
            <th class="table__head--text">Adres</th>
            <th class="table__head--text">Datum</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in table_data[arrkey].details" :key="key" :class="`table__row--color`">
            <td class="table__cell--default">
              <div v-if="item.soortnaam == 'halen'">
                <span v-b-tooltip.hover title="Halen" class="green"><i class="fas fa-arrow-left"></i></span>
              </div>
              <div v-if="item.soortnaam == 'wordt gebracht'">
                <span v-b-tooltip.hover title="Wordt gebracht" class="yellow"><i class="fas fa-arrow-left "></i></span>
              </div>
            </td>
            <td class="table__cell--default">
              <div v-if="item.statusnaam == 'historie'">
                <span class="dot dot--orange" v-b-tooltip.hover title="Historie"></span>
              </div>
              <div v-if="item.statusnaam == 'ingepland'">
                <span class="dot dot--blue" v-b-tooltip.hover title="Ingepland"></span>
              </div>
              <div v-if="item.statusnaam == 'nieuw'">
                <span class="dot dot--red" v-b-tooltip.hover title="Nieuw"></span>
              </div>
            </td>
            <td class="table__cell--default">{{ item.itemnummer }}</td>
            <td class="table__cell--default">{{ item.transporteurnaam }}</td>
            <td class="table__cell--default">{{ item.vervoerswijze }}</td>
            <td class="table__cell--default">
              <div>
                {{ item.adresnaam }}<br />
                {{ item.adresstraat }}<br />
                {{ item.adresplaats }}<br />
                {{ item.adrespostcode }}<br />
                {{ item.adresland }} <br />
                {{ item.contactpersoon }}
              </div>
            </td>
            <td class="table__cell--default">{{ item.datum }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TextBox from "@/components/TextBox.vue";
export default {
  props: {
    table_data: Array,
    table_style: [String, Number],
    export_documents: [Array, Object]
  },
  components: { TextBox },
  data: () => ({
    arrkey: 0,
    clicked: false,
  }),
  methods: {
    openDetails(arrkey) {
      this.clicked = true;
      this.arrkey = arrkey;
    },
  },
};
</script>
