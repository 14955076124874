<template>
  <div>
    <table class="_table">
      <tbody>
        <tr v-for="({datum, actienaam,icon,vraagprijs,omschrijving,gebruiker,documentno,documenttype,phasecode,bedrag,nummer,kostprijs}, key) in timeline_data" :key="`${key}${nummer}`" >
          <td class="timeline_110" v-html="renderActienaam({'key':key,'actienaam':actienaam, 'timeline_data': timeline_data})" />
          <td class="table_border--black" />
          <td class="timeline_110 border--right" v-html="renderDatum({'key':key, 'actienaam': actienaam, 'datum': datum, 'timeline_data': timeline_data})" />
          <td class="timeline_150 border--right _text-right ">
            <span v-html="renderDocNo({'key': key, 'datum': datum, 'actienaam': actienaam, 'gebruiker': gebruiker, 'documentno': documentno, 'documenttype': documenttype})"  />
            <span v-html="renderIcons({'icon': icon, 'actienaam': actienaam})" />
          </td>
          <td class="timeline_500 flex _row" v-html="renderInfo({'key': key, 'actienaam': actienaam, 'vraagprijs': vraagprijs, 'kostprijs': kostprijs, 'omschrijving': omschrijving, 'timeline_data': timeline_data, 'datum': datum})"></td>
          <td class="timeline_20 border--right _text-right" v-html="renderPhaseCode({'phasecode': phasecode})" />
          <td class="timeline_125 _text-right" v-html="renderPrice({'bedrag': bedrag})" />
        </tr>
      </tbody>
      <tfoot :key="timeline_total">
        <td v-for="i, key in 5" :key="key" /> 
        <td>Totaal:</td>
        <td v-html="renderTotalPrice({'timeline_total': timeline_total})" class="_text-right " />
      </tfoot>
    </table>
  </div>
</template>
<script>
import request from "@/functions/request";
import hasIndex from "@/functions/hasIndex";

export default {
  props: {
    id: String,
    klant_data: Object,
  },
  data: () => ({
    timeline_data: null,
    timeline_total: null,
  }),
  methods: {
    getData(id) {
      const endpoint = `timeline/${id}`;
      request(endpoint, "GET").then((values) => {
        const { timeline_info, timeline_total_price, error } = values;
        if (error) this.error = error;
        this.timeline_data = timeline_info;
        this.timeline_total = timeline_total_price;
      });
    },
    renderActienaam(value) {
      const {key, actienaam, timeline_data} = value;
      if (key !== 0 && actienaam === timeline_data[key - 1].actienaam) {
        return ''
      } else if (actienaam && !hasIndex(actienaam, 'vraagprijs')) {
        return `<div class="table_border--bottom">${actienaam}</div>`
      } else {
        return ''
      }
    },
    renderDatum(value){
      const {key, actienaam, datum, timeline_data } = value;
      if (!key){
        return datum
      } else if (actienaam !== timeline_data[key - 1].actienaam){
        return datum
      }else if (datum !== timeline_data[key - 1].datum){
        return datum
      }else{
        return '';
      }
    },
    renderDocNo(value){
      const { key,  actienaam, gebruiker,  documentno, documenttype} = value;
      if (!key){
        return this.klant_data.inkooporder
      }else if (actienaam && hasIndex(actienaam, 'vraagprijs aanpassing')){
        return gebruiker;
      }else if (actienaam && hasIndex(actienaam, 'prijsopgave')){
        return gebruiker;
      }else if (documentno && documenttype){
        return documentno + ' ' + documenttype;
      }else if (documentno && !documenttype){
        return documentno;
      }else{
        return documenttype;
      }
    },
    renderIcons(value){
      const { icon, actienaam } = value;
      if (icon && Number(icon) === 1){
        return '<i class="fas fa-arrow-left m1 center" />'
      }else if (icon && Number(icon) === 2){
        return '<i class="fas fa-arrow-right m1 center" />'
      }else if (actienaam && hasIndex(actienaam, 'verkoop')){
        return this.klant_data.orderno
      }
    },
    renderInfo(value){
      const { key, actienaam, vraagprijs, kostprijs , omschrijving, timeline_data, datum} = value;
      if (actienaam && actienaam.toLowerCase() === 'inkoop'){
        return '<div class="flex _row">'+
                '<div class="m1">Inkoper:<br>Leverancier:</div>'+
                `<div class="m1">${this.klant_data.inkoper} - ${this.klant_data.inkopernaam}`+
                  '<br>'+
                  `${this.klant_data.leverancier}`+
                  '<br>'+
                  `${this.klant_data.levnaam}`+
                  '<br>'+
                  `${this.klant_data.levadres}`+
                  '<br>'+
                  `${this.klant_data.levstad}`+
                  '<br>'+
                  `${this.klant_data.levadres}`+
                '</div>'+
              '</div>'+
              '<div class="flex _row">'+
                '<div class="m1">Inkoop prijs:</div>'+
                `<div class="m1">€${ this.klant_data.inkoopbedrag }</div>`+
              '</div>'
      }else if (actienaam && hasIndex(actienaam, 'prijsopgave')){
        return '<div>'+
                `<div>${omschrijving}</div>`+
                `<div>Vraagprijs: ${vraagprijs}</div>`+
                `<div>Kostprijs: ${kostprijs}</div>`+
                `<div>Totaal: ${this.klant_data.nettoprijs}</div>`+
              '</div>'
      }else if (actienaam && hasIndex(actienaam, 'vraagprijs aanpassing')){
        return `<span> Vraagprijs aangepast naar €${vraagprijs} <i class="fas fa-chart-line" /></span>`
      }else if (actienaam && hasIndex(actienaam, 'verkoop')){
        return '<div class="flex _row">'+
                '<div class="m1">Verkoper:<br>Klant</div>'+
                `<div class="m1">${this.klant_data.verkoper} - ${this.klant_data.verkopernaam}`+
                  '<br>'+
                  `${this.klant_data.klant}`+
                  '<br>'+
                  `${this.klant_data.klantnaam}`+
                  '<br>'+
                  `${this.klant_data.klantadres}`+
                  '<br>'+
                  `${this.klant_data.klantstad}`+
                  '<br>'+
                  `${this.klant_data.klantnaam}`+
                '</div>'+
              '</div>'+
              '<div class="flex _row">'+
                '<div class="m1">Factuur prijs:'+'<br>'+
                'Documenten:'+'<br>'+
                'Transport:'+'<br>'+
                'Commisie:'+'<br>'+
                'Werkplaats:'+'<br>'+
                'Nettoprijs:'+
                 '</div>'+
                '<div class="m1 _text-right">'+
                  `€${ this.klant_data.verkoopbedrag }`+'<br>'+
                  `€${ this.klant_data.documenten }`+'<br>'+
                  `€${ this.klant_data.transport }`+'<br>'+
                  `€${ this.klant_data.commissie }`+'<br>'+
                  `€${ this.klant_data.werkplaats }`+'<br>'+
                  `€${ this.klant_data.nettoprijs }`+
                '</div>'+
              '</div>'
      }else if (!key){
        return omschrijving
      }else if (actienaam !== timeline_data[key-1].actienaam){
        return omschrijving
      }else if (datum !== timeline_data[key-1].datum){
        return omschrijving
      }else{
        return omschrijving
      }
    },
    renderPhaseCode(value){
      const { phasecode } = value;
      return phasecode
    },
    renderPrice(value){
      const {bedrag}= value;
      if(bedrag != 0){
        return `<span>€${ bedrag }</span>`
      }
    },
    renderTotalPrice(value){
      const { timeline_total } = value;
      const formatted_total = Number(timeline_total && timeline_total.replace(/[,.]+/g, ""));
      if (formatted_total < 0 ){
        return `<span class="table--green">${timeline_total}</span>`
      }else{
        return `<span class="table--red">${timeline_total}</span>`
      }
    }
  },
  mounted() {
    this.getData(this.id);
  },
  watch: {
    id(newid) {
      this.getData(newid);
    },
  },
};
</script>
