<template>
  <div class="d-flex flex-row">
    <div v-if="!table_data" class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <table
      :class="`table--default table__border--${table_style} m-2 external__table--max-height `"
    >
      <thead :class="`table__head--${table_style}`">
        <tr>
          <th
          class="table__head--text"
            v-for="header in table_header"
            :key="header"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(wo_info, key) in table_data"
          :key="key"
          v-on:click="loadWorkorder(key)"
          v-b-toggle.collapse-0
          :class="`table__row--color`"
          style="max-height: 50px"
        >
          <td class="table__cell--default">{{ wo_info.Nummer }}</td>
          <td class="table__cell--default">{{ wo_info.Statusnaam }}</td>
          <td class="table__cell--default">{{ wo_info.Soort }}</td>
          <td class="table__cell--default">{{ wo_info.NaamLeverancier }}</td>
          <td class="table__cell--default">{{ wo_info.DatumAangemaakt }}</td>
          <td class="table__cell--default">€ {{ wo_info.Prijs }}</td>
        </tr>
      </tbody>
    </table>
    <b-collapse id="collapse-0" class="m-2 flex-grow-1" v-if="table_data">
      <b-card>
        <div class="d-flex flex-row w-100">
          <div class="flex-grow-1 m-1 form-group">
            <TextBox
              v-for="(value, key) in this.first_column"
              :key="key"
              :label="key"
              :value="`${table_data[arrkey][value]}`"
            />
          </div>
          <div class="flex-grow-1 m-1 form-group">
            <TextBox
              v-for="(value, key) in this.second_column"
              :key="key"
              :label="key"
              :value="`${table_data[arrkey][value]}`"
            />
          </div>
        </div>
        <div class="w100">
          <TextBox
            style="max-width: 81% !important"
            :label="`Omschrijving`"
            :value="`${table_data[arrkey].OpdrachtTekst}`"
          />
          <TextBox
            style="max-width: 81% !important"
            :label="`Interne Opmerkingen`"
            :value="`${table_data[arrkey].InterneOpmerkingen}`"
          />
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import TextBox from "@/components/TextBox.vue";
export default {
  components: { TextBox },
  props: {
    table_header: Array,
    table_data: Array,
    table_style: String,
    first_column: [Array, Object],
    second_column: [Array, Object],
  },
  data: () => ({ arrkey: 0 }),
  methods: {
    loadWorkorder(key) {
      this.arrkey = key;
    },
  },
};
</script>
