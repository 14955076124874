<template>
  <ul class="timeline">
    <li :data-year="timeline_data.orderdatum" data-text="Inkoop"></li>
    <li :data-year="timeline_data.orderontvangst"></li>
    <li :data-year="timeline_data.ontvangstdatum" data-text="Ontvangst"></li>
    <li :data-year="timeline_data.ontvangstfactuur"></li>
    <li :data-year="timeline_data.factuurdatum" data-text="Verkoop"></li>
    <li :data-year="timeline_data.factuuraflever"></li>
    <li :data-year="timeline_data.afleverdatum" data-text="Aflevering"></li>
  </ul>
</template>
<script>
export default {
  props: {
    timeline_data: [Array, Object],
  },
};
</script>
