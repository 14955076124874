<template>
  <div class="d-flex flex-row">
    <div class="flex-grow-2 m-3">
      <table :class="`table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">Werkorder</th>
            <th class="table__head--text">Status</th>
            <th class="table__head--text">Categorie</th>
            <th class="table__head--text">Datum</th>
            <th class="table__head--text table__data--right">Totaal bedrag</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in table_data"
            :key="key"
            v-on:click="openDetails(key)"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">{{ item.nummer }}</td>
            <td class="table__cell--default">{{ item.statusnaam }}</td>
            <td class="table__cell--default">{{ item.catagorienaam }}</td>
            <td class="table__cell--default">{{ item.Datumopdracht }}</td>
            <td class="table__data--right ">€ {{ item.Totaalbedrag }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex-grow-1 m-3" v-if="clicked">
      <div class="m-2 form-group d-flex flex-row">
        <div class="flex-grow-1 m-1">
          <TextBox :label="`Order nummer`" :value="table_data[arrkey].nummer" />
          <TextBox :label="`Soort`" :value="table_data[arrkey].soortnaam" />
          <TextBox :label="`Voertuig`" :value="table_data[arrkey].itemnummer" />
          <TextBox :label="`Status`" :value="table_data[arrkey].statusnaam" />
          <TextBox
            :label="`Datum opdracht`"
            :value="table_data[arrkey].Datumopdracht"
          />
          <TextBox
            :label="`Datum gepland`"
            :value="table_data[arrkey].Datumgepland"
          />
          <TextBox
            :label="`Datum opdracht`"
            :value="table_data[arrkey].Datumopdracht"
          />
          <TextBox
            :label="`Datum compleet`"
            :value="table_data[arrkey].Datumcompleet"
          />
          <TextBox
            :label="`Datum verwerkt`"
            :value="table_data[arrkey].Datumverwerkt"
          />
          <TextBox
            :label="`Opdrachtgever`"
            :value="table_data[arrkey].opdrachtgevernaam"
          />
          <TextBox :label="`Planner`" :value="table_data[arrkey].plannernaam" />
        </div>
        <div class="flex-grow-1 m-1">
          <TextBox :label="`Klant`" :value="table_data[arrkey].Klant" />
          <TextBox
            :label="`Werkzaamheden`"
            :value="`€ ` + table_data[arrkey].BedragWerk"
          />
          <TextBox
            :label="`Vloeistoffen`"
            :value="`€ ` + table_data[arrkey].Vloeistoffen"
          />
          <TextBox :label="`Uren`" :value="`N.V.T`" />
          <TextBox
            :label="`Klein materiaal`"
            :value="`€ ` + table_data[arrkey].kleinmatbedrag"
          />
          <TextBox
            :label="`Onderdelen`"
            :value="`€ ` + table_data[arrkey].BedragOnderdelen"
          />
          <TextBox
            :label="`Werk Derden`"
            :value="table_data[arrkey].BedragWerkDerden"
          />
          <TextBox
            :label="`Totaal Bedrag`"
            :value="`€ ` + table_data[arrkey].Totaalbedrag"
          />
          <TextBox
            :label="`Afgesproken prijs`"
            :value="`€ ` + table_data[arrkey].Afgesprokenprijs"
          />
        </div>
      </div>
      <h3 class="text-center">Werkzaamheden</h3>
      <table :class="`w-100 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">Regel</th>
            <th class="table__head--text">Werkzaamheden</th>
            <th class="table__head--text table__data--right">Bedrag</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in table_data[arrkey].activities"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">{{ item.Regelnummer }}</td>
            <td class="table__cell--default" style="max-width: 500px;">{{ item.Omschrijving }}</td>
            <td class="table__data--right table__cell--default">€ {{ item.Bedrag }}</td>
          </tr>
        </tbody>
      </table>
      <h3 class="text-center">Vloeistoffen</h3>
      <table :class="`w-100 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">Olie 10W40</th>
            <th class="table__head--text">Olie 5W30</th>
            <th class="table__head--text">Koel vloeistof</th>
            <th class="table__head--text">Antivries</th>
            <th class="table__head--text">Ruiten sproeier</th>
            <th class="table__head--text">Rem vloeistof</th>
            <th class="table__head--text">Hydrauliek olie</th>
            <th class="table__head--text">Freon laag</th>
            <th class="table__head--text">Freon hoog</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in table_data[arrkey].liquids"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">{{ item.Olie10W40 }}</td>
            <td class="table__cell--default">{{ item.Olie5W30 }}</td>
            <td class="table__cell--default">{{ item.Koelvloeistof }}</td>
            <td class="table__cell--default">{{ item.Antivries }}</td>
            <td class="table__cell--default">{{ item.Ruitensproeier }}</td>
            <td class="table__cell--default">{{ item.Remvloeistof }}</td>
            <td class="table__cell--default">{{ item.Hydrauliekolie }}</td>
            <td class="table__cell--default">{{ item.FreonLaag }}</td>
            <td class="table__cell--default">{{ item.FreonHoog }}</td>
          </tr>
        </tbody>
      </table>
      <h3 class="text-center">Uren</h3>
      <table :class="`w-100 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">Monteur</th>
            <th class="table__head--text">Start</th>
            <th class="table__head--text">Eind</th>
            <th class="table__head--text table__data--right">Bedrag</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in table_data[arrkey].hours"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">{{ item.naam }}</td>
            <td class="table__cell--default">{{ item.Start }}</td>
            <td class="table__cell--default">{{ item.eind }}</td>
            <td class="table__cell--default table__data--right">
              € {{ item.bedrag }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TextBox from "@/components/TextBox.vue";
export default {
  props: {
    table_data: Array,
    table_style: [String, Number],
  },
  components: { TextBox },
  data: () => ({
    arrkey: 0,
    clicked: false,
  }),
  methods: {
    openDetails(arrkey) {
      this.clicked = true;
      this.arrkey = arrkey;
    },
  },
};
</script>
