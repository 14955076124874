<template>
  <div class="d-flex flex-row">
    <div class="flex-grow-2">
      <table :class="`table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">Code</th>
            <th class="table__head--text">Naam</th>
            <th class="table__head--text table__data--right">Bedrag</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in table_data"
            :key="key"
            @click="openData(item.code, $event)"
          >
            <td class="table__cell--default">{{ item.code }}</td>
            <td class="table__cell--default">{{ item.naam }}</td>
            <td class="table__data--right table__cell--default">
              € {{ item.bedrag }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex-grow-1 ml-3">
      <table :class="`w-100 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr v-if="this.current">
            <th class="table__head--text">Rekening</th>
            <th class="table__head--text">Dt</th>
            <th class="table__head--text">Document</th>
            <th class="table__head--text">Datum</th>
            <th class="table__head--text">Omschrijving</th>
            <th class="table__head--text">Omschrijving</th>
            <th class="table__head--text table__data--right">Bedrag</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in current"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">{{ item.Rekening }}</td>
            <td class="table__cell--default">{{ item.Doctype }}</td>
            <td class="table__cell--default">{{ item.Docnummer }}</td>
            <td class="table__cell--default">{{ item.Datum }}</td>
            <td class="table__cell--default">{{ item.Omschrijving1 }}</td>
            <td class="table__cell--default">{{ item.Omschrijving2 }}</td>
            <td class="table__data--right table__cell--default">€ {{ item.Bedrag }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    table_data: Array,
    financial_data: Array,
    table_style: String,
  },
  data: () => ({
    current: null,
    previous: null,
  }),
  mounted() {
    this.financial_data.forEach((item) => {
      if (this[item.Phasecode.replace(/\s/g, "")] == undefined){
        this[item.Phasecode.replace(/\s/g, "")] = []
      } 
      this[item.Phasecode.replace(/\s/g, "")] = [
        ...this[item.Phasecode.replace(/\s/g, "")],
        item,
      ];
    });
  },
  methods: {
    openData(phasecode, event) {
      this.clearstyle();
      event.srcElement.parentNode.style.backgroundColor = "#ece9e9";

      this.current = this[phasecode.replace(/\s/g, "")];
      this.previous = event.srcElement.parentNode;
    },
    clearstyle() {
      if (this.previous) this.previous.style.backgroundColor = "white";
    },
  },
};
</script>
